import { FC, ReactElement } from 'react'
import { Tooltip } from '~/core/ui/Tooltip'

const TooltipWithButton: FC<{
  content: string
  isShowTooltip: boolean
  children: ReactElement
}> = ({ content, isShowTooltip, children }) => {
  return isShowTooltip ? (
    <Tooltip align="center" content={content} position="top">
      {children}
    </Tooltip>
  ) : (
    children
  )
}
export default TooltipWithButton
