import { gql } from 'urql'
import { EmployeeProfileType } from '../types'

const QueryProfileEmployee = gql<
  {
    internalApplicationProfilesShow: EmployeeProfileType
  },
  {
    employeeId?: number
  }
>`
  query ($employeeId: Int!) {
    internalApplicationProfilesShow(employeeId: $employeeId) {
      id
      fullName
      email
      headline
      phoneNumber
      coverLetter
      profileCvs {
        id
        attachments {
          id
          file
          blobs
        }
      }
    }
  }
`

export default QueryProfileEmployee
