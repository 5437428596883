import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import useBoundStore from '~/lib/store'
import QueryProfileEmployee from '../graphql/query-profile-employee'

const useProfileEmployeeHook = () => {
  const { user } = useBoundStore()
  const {
    isLoading,
    data,
    trigger: fetchEmployeeProfile
  } = useQueryGraphQL({
    query: QueryProfileEmployee,
    variables: {
      employeeId: Number(user.id)
    },
    shouldPause: !Number(user.id)
  })

  return {
    employeeProfile: data?.internalApplicationProfilesShow,
    isFetchingEmployeeProfile: isLoading,
    fetchEmployeeProfile
  }
}

export default useProfileEmployeeHook
