import { gql } from 'urql'

const QueryPublicTermsAndConditionsCareersSetting = gql`
  query ($tenantSlug: String!) {
    publicTermsAndConditionSetting(tenantSlug: $tenantSlug) {
      id
      values
    }
  }
`

export default QueryPublicTermsAndConditionsCareersSetting
