const CreateReferralMutation = `
  mutation (
    $fullName: String!,
    $email: String!,
    $phoneNumber: String,
    $resumeFile: File!
    $introduction: String
    $jobId: Int!
  ) {
    referralsCreate(
      input: {
        fullName: $fullName,
        email: $email,
        phoneNumber: $phoneNumber,
        resumeFile: $resumeFile,
        introduction: $introduction,
        jobId: $jobId
      }
    ) {
      referral {
        id
      }
    }
  }
`

export default CreateReferralMutation
