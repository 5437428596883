import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ZodType, ZodTypeDef } from 'zod'
import { SettingEditorFormType } from '~/components/Settings/Careers/Editor/CustomizeSettingTab'
import { IFormAction } from '~/core/@types/global'
import { Dialog } from '~/core/ui/Dialog'
import { ApplicationFormType, JobType } from '~/lib/features/apply/jobId/types'
import { JobDetailType } from '~/lib/features/referrals/types'
import ApplicationForm from './ApplicationForm'

const ApplicationFormModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  job: JobType | JobDetailType
  isSubmitting?: boolean
  defaultValue?: ApplicationFormType
  onSubmit?: (
    data: ApplicationFormType,
    formAction: IFormAction
  ) => Promise<void>
  disableFields?: Array<string>
  schema: ZodType<any, ZodTypeDef, any>
  isPublicPage?: boolean
}> = ({
  open,
  setOpen,
  job,
  defaultValue,
  isSubmitting,
  onSubmit,
  disableFields,
  schema,
  isPublicPage = false
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      size="md"
      onOpenChange={setOpen}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      label={`${t('careers:applied:yourApplication')}`}
      description={job.title}
      headingClassName="pb-4 tablet:pb-5">
      <ApplicationForm
        isSubmitting={!!isSubmitting}
        job={job}
        onFinishCallback={onSubmit}
        defaultValue={defaultValue}
        disableFields={disableFields}
        schema={schema}
        isPublicPage={isPublicPage}
      />
    </Dialog>
  )
}

export default ApplicationFormModal
