import { IUserInformation } from '~/core/@types/global'
import { EmployeeProfileType } from '../types'

export const mappingProfileEmployeeToApplicationForm = ({
  profile,
  user
}: {
  profile?: EmployeeProfileType
  user: IUserInformation
}) => {
  return {
    fullName: String(profile?.fullName || user.fullName),
    email: String(profile?.email?.[0] || user.email),
    headline: profile?.headline || user.jobTitle,
    phoneNumber: String(profile?.phoneNumber || user.phoneNumber),
    attachments: profile?.profileCvs?.[0]?.attachments || [],
    coverLetter: profile?.coverLetter
  }
}
