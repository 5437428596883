const CreateInternalApplicationMutation = `
  mutation (
    $jobId: Int!
    $fullName: String!
    $phoneNumber: String!
    $email: String!
    $resumeFile: File
    $headline: String
    $coverLetter: String
    $termsAndConditions: Boolean
  ) {
    internalApplicantsCreate(
      input: {
        jobId: $jobId
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        resumeFile: $resumeFile
        headline: $headline
        coverLetter: $coverLetter
        termsAndConditions: $termsAndConditions
      }
    ) {
      profile {
        id
      }
    }
  }
`

export default CreateInternalApplicationMutation
