import { useEffect, useMemo } from 'react'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryPublicTermsAndConditionsCareersSetting from '../graphql/query-public-terms-and-conditions-careers-setting'
import QueryTermsAndConditionsCareersSetting from '../graphql/query-terms-and-conditions-careers-setting'
import { ITermsAndConditions } from '../types'

const useTermAndConditionHook = ({
  isPublicPage = false,
  tenantSlug
}: {
  isPublicPage?: boolean
  tenantSlug?: string
}) => {
  const { isLoading, data, trigger } = useQueryGraphQL({
    query: isPublicPage
      ? QueryPublicTermsAndConditionsCareersSetting
      : QueryTermsAndConditionsCareersSetting,
    variables: {
      ...(tenantSlug && isPublicPage ? { tenantSlug } : undefined)
    },
    shouldPause: true
  })

  useEffect(() => {
    if (!data) trigger()
  }, [])

  return {
    isFetchingTermAndConditions: isLoading,
    termAndConditions: useMemo(() => {
      return isPublicPage
        ? data?.publicTermsAndConditionSetting?.values
        : data?.tenantTermsAndConditionsSettingShow?.values
    }, [data]) as ITermsAndConditions,
    refetchTermAndConditions: trigger
  }
}

export default useTermAndConditionHook
