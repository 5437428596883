import { TFunction } from 'i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  convertFileSizeToBytes
} from '~/lib/schema'
import { MAX_CHARACTERS_INTRODUCTION } from '~/core/constants/enum'

const schemaInternalApplicationForm = (t: TFunction) => {
  return z.object({
    fullName: z
      .string()
      .trim()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(100, {
        message: `${t('form:field_max_number_required', { number: 100 })}`
      }),
    email: z
      .string()
      .trim()
      .refine((value) => value.trim() !== '', {
        message: `${t('form:requiredField')}`
      })
      .refine((value) => regexEmailValidation.test(value), {
        message: `${t('form:invalid_email')}`
      }),
    headline: z
      .nullable(
        z.string().max(200, {
          message: `${t('form:field_max_number_required', { number: 200 })}`
        })
      )
      .optional(),
    phoneNumber: z
      .string()
      .trim()
      .min(1, {
        message: `${t('form:requiredField')}`
      })
      .max(15, {
        message: `${t('form:field_max_number_required', { number: 15 })}`
      }),
    resumeFile: z.nullable(
      z
        .any()
        .array()
        // .length(1, {
        //   message: `${t('form:requiredField')}`
        // })
        .optional()
        .refine(
          (files) =>
            !files?.[0] ||
            files?.[0]?.size <= convertFileSizeToBytes({ size: MAX_FILE_SIZE }),
          `${t('form:invalid_upload_file', {
            maximumSizeFile: MAX_FILE_SIZE
          })}`
        )
        .refine(
          (files) =>
            !files?.[0] || ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
          `${t('form:invalid_upload_file', {
            maximumSizeFile: MAX_FILE_SIZE
          })}`
        )
    ),
    coverLetter: z
      .nullable(
        z.string().max(MAX_CHARACTERS_INTRODUCTION, {
          message: `${t('form:field_max_number_required', {
            number: MAX_CHARACTERS_INTRODUCTION
          })}`
        })
      )
      .optional(),
    termsAndConditions: z.boolean().optional()
  })
}

export default schemaInternalApplicationForm
