import { gql } from 'urql'

const QueryTermsAndConditionsCareersSetting = gql`
  query {
    tenantTermsAndConditionsSettingShow {
      id
      values
    }
  }
`

export default QueryTermsAndConditionsCareersSetting
